  /* this file contains footer styles */
  footer h2{
    color: #fff;
    font-size: 36px;
    font-family:'Helvetica Neue Medium' !important;
    font-weight: bold;
  }

  footer p{
    color: #fff;
    font-size: 18px;
    font-family: 'Helvetica Neue Light';
  }

  .footer-fresh-heading{
    color: #fff;
    font-size: 18px;
    font-family: 'Open Sans' !important;
    font-weight: bold;
  }


  .footer-sub-heading{
    font-size: 16px;
    font-family: 'Helvetica Neue Medium' !important;
    color: #fff;
  }


  .bottom-section{
    background: #223a59;
  }

  .bottom-inset{
    padding: 50px 50px;
  }

  .bottom-list{
    font-family: inherit;
    list-style: none;
    font-family: 'Helvetica Neue Light' !important;
  }

  .bottom-list li{
    color: #fff;
    font-size: 16px;
    line-height: 30px;
  }

  .bottom-list a:hover{
    text-decoration: none;
  }

  .bottom-section-fresh{
    background: #355784;
  }

  .bottom-section-fresh img{
    margin: 10px 0 10px 60px;
    border-radius: 50%;
  }

  .bottom-section-fresh h2{
    color: #fff;
    /*float:right;
    top: 27px;
    left: 0px;*/
    bottom: 50px;
    left: 150px;
    position: relative;
  }

  .fresh-inset{
    width: 25%;
    height: 80px;
  }

  .vertical-line{
    border-right: 2px solid #fdb813;
    min-height: 285px;
  }

  .telephone-link{
    color:#fff;
  }

  .telephone-link:hover{
    text-decoration: none;
    color:#fff;
  }

  .fresh-text{
    /*padding: 127px 30px 0 0;*/
    padding-top: 127px;
  }

  .freeform-row .freeform-column .freeform-label.freeform-required::after {
    content: " " !important;
    margin-left: 0 !important;
  }

  .freeform-row .freeform-column{
    padding: 0 0 !important;
  }

  #contact-button{
    width:100%;
    height: 50px;
  }
